<template>
  <div class="home">
    <div class="header">
      <BusinessInformation />
      <div class="menu">
        <div class="box">
          <div class="item" @click="showWithdrawModal = true">
            <img src="@/assets/icons/withdraw.svg" alt="Icon" />
            <span>{{ $t("home.menu1") }}</span>
          </div>
          <div class="item" @click="showDepositModal = true">
            <img src="@/assets/icons/recharge.svg" alt="Icon" />
            <span>{{ $t("home.menu2") }}</span>
          </div>
        </div>
        <div class="lang">
          <LangSelect :flag="true" />
        </div>
      </div>
    </div>
    <md-content class="body md-scrollbar">
      <md-tabs
        :md-active-tab="categoryTab"
        class="md-gray"
        @md-changed="onChangeTab"
        md-alignment="fixed"
        md-theme="body-yellow"
      >
        <md-tab
          :md-label="category.name"
          v-for="(category, index) in categoies"
          :key="index"
          :id="category._id"
        >
          <div style="text-align: center" v-if="tabLoading">
            <md-progress-spinner
              :md-diameter="50"
              :md-stroke="5"
              md-mode="indeterminate"
            ></md-progress-spinner>
          </div>
          <div
            style="margin: 5px 0"
            v-for="(product, index) in products"
            :key="index"
          >
            <router-link
              :to="{ name: 'trading', params: { id: product._id } }"
              class="card-body"
            >
              <div class="image-icon">
                <md-card-media
                  v-for="(currency, index) in getCurrencyByCodeName(
                    product.code
                  )"
                  :key="index"
                >
                  <img :src="getIconSrc(currency)" />
                </md-card-media>
              </div>
              <div style="flex-grow: 6">
                <div style="font-size: 14px; color: white; font-weight: 600">
                  {{ product.name }}
                </div>
                <div style="font-size: 12px; color: rgb(201, 201, 201); font-weight: 600">
                  {{ product.code }}
                </div>
              </div>
              <div style="flex-grow: 0">
                <div style="font-size: 12px; color: rgb(201, 201, 201); font-weight: 600">Giá hiện tại</div>
                <div
                  :style="{
                    color: product.fluctuation > 0 ? '#00fa00' : 'red',
                  }"
                >
                  {{ product.price || 0 }}
                </div>
              </div>
            </router-link>
          </div>
        </md-tab>
      </md-tabs>
    </md-content>

    <FooterApp />
    <WithdrawModal
      :show="showWithdrawModal"
      @close="showWithdrawModal = false"
    />
    <DepositModal :show="showDepositModal" @close="showDepositModal = false" />
  </div>
</template>

<script>
import FooterApp from "@/components/FooterApp.vue";
import BusinessInformation from "@/components/my/BusinessInformation.vue";
import DepositModal from "@/components/my/DepositModal.vue";
import WithdrawModal from "@/components/my/WithdrawModal.vue";
import LangSelect from "@/components/LangSelect.vue";
import request from "@/request";
import { pricesSocket } from "@/sockets";

export default {
  name: "HomeView",
  components: {
    FooterApp,
    BusinessInformation,
    DepositModal,
    WithdrawModal,
    LangSelect,
  },
  data() {
    return {
      socket: null,
      categoies: [],
      products: [],
      categoryTab: "",
      showDepositModal: false,
      showWithdrawModal: false,
      tabLoading: false,
    };
  },

  methods: {
    getProductList(id) {
      this.tabLoading = true;
      request
        .get("api/category/summary/products/" + id)
        .then((res) => {
          this.products = res.data.data;
          this.tabLoading = false;
        })
        .catch((error) => {
          const message = this.$response(error.data?.error?.message);
          this.$toast.error(message || "error");
        });
    },

    onChangeTab(tab) {
      if (tab) {
        this.getProductList(tab);
        this.socket.emit("get-category-prices", tab);
      }
    },

    getCurrencyByCodeName(code) {
      return code.split("/");
    },

    getIconSrc(currency) {
      try {
        return require(`@/assets/images/icons/${currency.toLowerCase()}.svg`);
      } catch (err) {
        return require(`@/assets/icons/vn.svg`);
        // console.log(err);
      }
    },

    categoryPricesListener(data) {
      const productPrice = data.reduce((prev, curr) => {
        prev[curr.id] = curr;
        return prev;
      }, {});

      this.products.map((p) => {
        this.$set(p, "price", productPrice[p._id]?.price || 0);
        this.$set(p, "fluctuation", productPrice[p._id]?.fluctuation || 0);
      });
    },
  },

  created() {
    request
      .get("api/category/summary/list")
      .then((res) => {
        this.categoies = res.data.data;
      })
      .catch((error) => {
        const message = this.$response(error.data?.error?.message);
        this.$toast.error(message || "error");
      });
    this.socket = pricesSocket.getSocket;
  },
  mounted() {
    this.socket.on("category-prices", this.categoryPricesListener);
  },
  beforeDestroy() {
    this.socket.off("category-prices", this.categoryPricesListener);
    this.socket.emit("stop-category-prices");
  },
};
</script>

<style lang="scss">
.md-content.md-theme-body-yellow {
  background-color: transparent !important;
}
</style>

<style lang="scss" scoped>
.home {
  background: #141414;
  min-height: 100vh;
  .header {
    background: url("@/assets/images/home/banner.jpg") no-repeat top;
    width: 100%;
    min-height: 100px;
    height: auto;
    padding: 100px 10px 10px 10px;
    background-size: contain;
    .menu {
      background-color: #292929;
      margin-top: 10px;
      border-radius: 6px;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      .box {
        display: flex;
        background-color: #141414;
        padding: 10px;
        width: calc(100% - 130px);
        border-radius: 6px;
        .item {
          text-align: center;
          width: 50%;
          img {
            width: 20px;
            height: 20px;
          }
          span {
            font-size: 12px;
            color: #fff;
            margin-left: 5px;
          }
        }
      }
      .lang {
        width: 100px;
        margin-left: 30px;
      }
    }
  }
  .body {
    max-height: calc(var(--vh, vh) * 100 - 220px - 60px);
    background-image: var(--img-bg);
    background-position: center;
    overflow: auto;
  }
  .card-body {
    display: flex;
    justify-content: space-between;
    /* border-bottom: 1px solid rgb(43, 42, 42);
    background: #141414 !important; */
    width: 100%;
    height: auto;
    padding: 5px 20px;
  }
  .card-body:hover {
    text-decoration: none;
  }
  .md-tab {
    background: transparent;
  }
  .image-icon {
    flex-grow: 1;
  }
  .md-card-media {
    width: 15px;
    height: 15px;
    margin-bottom: 3px;
  }
  .md-card-media img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
